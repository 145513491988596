<template>
  <div class="viewPost" >
    <div class="title">{{$t('viewedPosts')}}</div>
    <div class="content">
      <div v-for="item in positionList" :key="item.id">
        <viewPostItem :item="item" />
      </div>
      <!-- <noDataImg v-if="positionList.length === 0" /> -->
    </div>
  </div>
</template>

<script>
import viewPostItem from "~hph/components/booth/viewPostItem";

export default {
  name: "viewPost",
  components: {
    viewPostItem,
  },
  props: {
    positionList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="less">
.viewPost {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding-top: 8px;
    padding-left: 16px;
  }
  .content {
    margin-top: 12px;
    position: relative;
    min-height: 200px;
  }
}
</style>
